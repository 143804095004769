/**请求路径
 *
 */
import { get, post, postBlob} from './request'

export default {
    login: params => {            //登录
        // return post('/pub/Member/login',params)
        return post('/login',params)
    },
    getVeryCode: params => {            //发邮箱验证码
        return post('/Register/getVeryCode',params)
    },
    member: params => {            //注册新用户
        return post('/Register/member',params)
    },
    forgetPwd: params => {            //找回密码
        return post('/Forget/forgetPwd',params)
    },
    forgetpwdVery: params => {            //找回密码TOKEN
        return post('/Forget/forgetpwdVery',params)
    },
    forgetpwdStep2: params => {            //重置密码
        return post('/Forget/forgetpwdStep2',params)
    },
    modifyPassword: params => {            //修改密码
        return post('/Safe/upPwd',params)
    },
    museumList: params => {      //博物馆列表
        return get('/Pub/museumList', params)
    },    
    getRelicTotal: params => {    //文物总数量
        return post('/Relic/getTotal', params)
    },
    getList: params => {         //文物列表
        return post('/relic', params)
    },
    collectDetail: params => {   //文物详情
        return post('/relic/detail', params)
    },
    collectDownload: params => {   
        return postBlob('/download', params)
    },
    exportExecl: params => {     //导出
        return post('Relic/export', params)
    },
    editRelic: params => {     //编辑
        return post('Relic/edit', params)
    },
    getMuseumList:params => {      //用户中心博物馆列表
        return get('/Museum/getlist', params)
    }, 
    fileUpload:params => {     
        return get('/File/upload', params)
    }, 
    imgUpload:params => {  //库房、展厅等图片上传     
        return get('/File/up', params)
    }, 
    getWarehouse:params => {  
        return get('/Warehouse/all', params)
    }, 
    getExhibith:params => {      
        return get('/Exhibithall/all', params)
    }, 
    museumEdit: params =>{
        return get('/Museum/edit', params)
    },
    museumDelete: params =>{
        return get('/Museum/del', params)
    },
    museumDetail: params =>{
        return get('/Museum/detail', params)
    },

    warehouseEdit: params =>{
        return post('/Warehouse/edit', params)
    },
    getWarehouseList:params => {      
        return get('/Warehouse/getList', params)
    }, 
    
    warehouseDelete: params =>{
        return post('/Warehouse/del', params)
    },
    warehouseDetail: params =>{
        return get('/Warehouse/detail', params)
    },
    inventoryEdit: params =>{
        return post('/Inventory/edit', params)
    },
    getInventoryList:params => {      
        return get('/Inventory/getList', params)
    },
    inventoryDelete: params =>{
        return post('/Inventory/del', params)
    },
    inventoryDetail: params =>{
        return get('/Inventory/detail', params)
    },
    relicList:params => {      
        return get('/Inventory/relicList', params)
    },
    exhibitEdit: params =>{
        return post('/Exhibithall/edit', params)
    },
    getExhibitList:params => {      
        return get('/Exhibithall/getList', params)
    }, 
    
    exhibitDelete: params =>{
        return post('/Exhibithall/del', params)
    },
    exhibitDetail: params =>{
        return get('/Exhibithall/detail', params)
    },

    getMemberList:params => {      //用户中心用户列表
        return get('/Member/getlist', params)
    }, 
    getEmail:params => {      //获取用户邮箱
        return get('/Member/getEmail', params)
    }, 
    getEmailConf:params => {      //获取用户邮箱
        return get('/Site/getEmailConf', params)
    }, 
    emailConfSave:params => {      //获取用户邮箱
        return get('/Site/emailConfSave', params)
    }, 
    sendEmailCode:params => {      //获取用户邮箱验证码
        return get('/Member/sendEmailCode', params)
    }, 
    bindEmail:params => {      //获取用户邮箱验证码
        return get('/Member/bindEmail', params)
    }, 
    memberEdit: params =>{
        return get('/Member/edit', params)
    },
    memberDelete: params =>{
        return get('/Member/del', params)
    },
    memberDetail: params =>{
        return get('/Member/info', params)
    },
    getLogList: params =>{
        return get('/Log/getList', params)
    },
    relicEditRows: params =>{
        return get('/log/relicEditRows', params)
    },
    getRelicEdit: params =>{
        return get('/log/relicEdit', params)
    },
    agreeRelicEdit: params =>{
        return get('/log/agreeRelicEdit', params)
    },
    getTreeList: params =>{
        return post('/init', params)
    },
    getYearFormat: params =>{
        return get('/Cate/getYearFormat', params)
    },
    powerConf: params =>{
        return get('/power/conf', params)
    },
    powerEdit: params =>{
        return get('/power/Edit', params)
    },
    getCurPower: params =>{
        return get('/power/getCurPower', params)
    },
    downExcel: params =>{
        return get('/relic/export', params)
    },
    downWord: params =>{
        return get('/relic/exportWord', params)
    },
    
    chartClassify: params =>{
        return get('/Chart/classify', params)
    },
    chartYear: params =>{
        return get('/Chart/year', params)
    },
    chartLevel: params =>{
        return get('/Chart/level', params)
    },
    chartSource: params =>{
        return get('/Chart/source', params)
    },
    chartIntact: params =>{
        return get('/Chart/intact', params)
    },
    chartTime: params =>{
        return get('/Chart/time', params)
    },
    getRelicList: params =>{
        return get('/relic', params)
    },
    register: params =>{
        // return post('/pub/Member/register', params)
        return post('/register', params)
    },
    
}