/**
 * axios封装
 * 请求拦截、相应拦截、错误统一处理=
 */


import axios from 'axios'

// let loadingInstance = null				// 全局加载loadding

const instance = axios.create({    //创建axios实例
    timeout: 300000, // 设置超时时间10s
    baseURL: window.global_config.BASE_URL		  // 请求配置静态文件config/config.js		dev模式下为 api 走代理模式
})


// 设置默认请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 跨域允许携带cookie
instance.defaults.withCredentials = true

// 请求拦截器
instance.interceptors.request.use(
    request => {

        // loadingInstance = Toast.loading({
        //     message: '拼命加载中...',
        //     forbidClick: true,
        //     duration: 0,
        //     overlay: true,
        //     loadingType: 'spinner'
        // });

        if (request.method === 'get') { // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
            request.params = {
                ...request.params,
                t: new Date().getTime()
            }
        }

        return request
    },
    error => {
        return error
    }
)

function getUrlType(){
    let url = window.location.href;
    let res = ''
    if(url.indexOf('suitangluoyang') !=-1 ){
        res = 'suitangluoyang'
    }else if(url.indexOf('lybaban') !=-1){
        res = 'lybaban'
    }else if(url.indexOf('zglyzjng') !=-1){
        res = 'zglyzjng'
    }else if(url.indexOf('lymsmuseum') !=-1){
        res = 'lymsmuseum'
    }

    return res
}

// 响应拦截
instance.interceptors.response.use(
    response => {

        let code = response.data.code
        let status = response.status
        // 1002:非法令牌和令牌过期 5012:其他客户端登录  6005 登录过期
        if (code === 1002 || code === 5012 || code == 6005 || code == 403) {
            // 清除本地所有数据
            localStorage.clear();
            // window.location.href = '/';
        }

        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        if (status === 200) { // 请求成功
            return Promise.resolve(response)
        } else { // 错误的请求  抛出错误
            return Promise.reject(response)
        }
    },

    error => {

        // if (loadingInstance) {
        //     loadingInstance.clear()
        // }
        if (error.response) {
            // 根据请求失败的http状态码去给用户相应的提示

            return Promise.reject(error)
        } else {
            console.error(`请求错误${error}`)
            return Promise.reject(new Error('请求超时, 请刷新重试'))
        }

    }

)


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    url = getUrlType() + url
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: params,
            headers : {
                'name': localStorage.getItem('loginName'),
                'token': localStorage.getItem('token'),
                'timestamp':  parseInt(new Date().getTime() / 1000) + '',
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    url = getUrlType() + url
    return new Promise((resolve, reject) => {
        instance.post(
            url, 
            params, {
                headers : {
                    'name': localStorage.getItem('loginName'),
                    'token': localStorage.getItem('token'),
                    'timestamp':  parseInt(new Date().getTime() / 1000) + '',
                },
        }).then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}



/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function postBlob(url, params) {
    url = getUrlType() + url
    return new Promise((resolve, reject) => {
        instance.post(
            url, 
            params, {
                headers : {
                    'name': localStorage.getItem('loginName'),
                    'token': localStorage.getItem('token'),
                    'timestamp':  parseInt(new Date().getTime() / 1000) + '',
                },
                responseType:"blob"
        }).then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
